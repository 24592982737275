<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  async created() {
    await this.$store.dispatch('GET_USER_INFO')
    await this.$store.dispatch('GET_MENU_INFO')

  }
}
</script>

<style lang="scss">
@import "assets/css/reset";
@import "assets/css/public";
@import "assets/css/iconfont";
</style>
