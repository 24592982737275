import http from '@/utils/axios'

const data = {
  // 修改密码
  updatePassword: (data) => http.post('/tenant-user/account/front_desk/modify/password', data),
  // 获取用户信息
  getUserInfo: () => http.get('/userInfo'),
  // 获取菜单
  getAuthTree: (data) => http.get('/tenant-user/auth/front_desk/authTree', data),
  // 查询应用
  getApps: (data) => http.get('/appstore/app/get', data),
  // 查询应用解决方案详情
  getAppSchemeInfo: (data) => http.get('/appstore/app/scheme/get/appSchemeCode', data),
  // 获取帮助文档数据
  getDirectoryTree: (data) => http.get(`/eds/directory/get/code`, data),
  // 根据文档查文档数据
  getDocumentName: (data) => http.get(`/eds/directory/getDocumentNameOrCode`, data),
  // 获取帮助文档详情
  getDirectoryDetail: (data) => http.get(`/eds/directory/get/document/content/${data}`),

  // 获取全局系统菜单
  getGlobalMenuData: (data) => http.get('/tenant-user/auth/front_desk/global/authTree', data),
  // 获取应用下系统菜单
  getAppMenuData: (data) => http.get('/tenant-user/auth/front_desk/app/authTree', data),
  // 工单管理添加（吐槽功能）
  ticketAdd: (data) => http.post('/appstore/ticket/add', data),

  // 新增收藏
  addCollect: (data) => http.post('/appstore/personal/collection/add', data),
  // 修改收藏
  updateCollect: (data) => http.post('/appstore/personal/collection/modify', data),
  // 查询收藏数据
  queryCollect: () => http.get('/appstore/personal/collection/query'),
  // 删除收藏
  deleteCollect: (data) => http.delete('/appstore/personal/collection/delete', data),
  // 根据收藏url判断页面是否已经收藏
  getCollectBypath: (data) => http.get('/appstore/personal/collection/getFavoriteTags', data),
  // 查所有的收藏文件夹
  getAllColletFolder: (data) => http.get('/appstore/personal/collection/getType', data),
  // 根据系统编码获取全局按钮权限
  getGlobalBtnAuth: (data) => http.get('/tenant-user/user/front_desk/global/queryCode', data),
  // 根据系统编码获取应用按钮权限
  getAppBtnAuth: (data) => http.get('/tenant-user/user/front_desk/app/queryCode', data),
  // 获取站内信通知token
  getNoticeToken: () => http.get('edt-notice/notice/token'),
  // 查看我的通知
  getNotice: (data) => http.post('app-monitor/notice/queryPage', data),
  // 读取一条消息
  readNotice: ({ id }) => http.put(`app-monitor/notice/read?id=${id}`),
  // 全部已读
  readAll: () => http.put(`app-monitor/notice/readAll`),
  // 根据多个字典类型查询对应字典数据
  getDistTypeList: (data) => http.get(`/eds/system/dictionary/data/distTypes/list`, data),
  // 查看我的通知配置(前端)
  getNoticeConfig: () => http.get(`app-monitor/notice/queryConfig/front`),
  // 修改我的通知配置(前端)
  modifyNoticeConfig: (data) => http.put(`app-monitor/notice/modifyConfig/front`, data),
}

export default data
