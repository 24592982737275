import Vue from 'vue'
import VueRouter from 'vue-router'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import { routes } from './routes'
import store from '@/store'

Vue.use(VueRouter)
const router = new VueRouter({
  base: '/',
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  NProgress.start()

  next()
})

router.afterEach((to, from) => {
  NProgress.done()
  window.scrollTo(0, 0)
  let toArr = to.path.split('/')
  let fromArr = from.path.split('/')
  if(toArr[1] != fromArr[1]) {
    store.dispatch('GET_AUTH_BTN')
  }
})

export default router
