import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "./store";
import jsCookie from "js-cookie";
import ElementUI from "element-ui";
import "@/assets/css/element-variables.scss";
import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';
import '@/utils/directives'

Vue.config.productionTip = false;

Vue.prototype.$cookie = jsCookie;
Vue.use(ElementUI, {
  size: "mini",
});

Vue.use(mavonEditor)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
