/**
 * 显示层级数据
 * @param tree {Array} 树数据
 * @param func {Function} 回调函数
 * @param field {String} 字段名称
 * @param path {Array} 路径数据
 * @returns {*[]|[]|*}
 */
export function treeFindPath(tree, func, path = []) {
  if (!tree) return [];
  for (const data of tree) {
    path.push(data);
    if (func(data)) return path;
    if (data.children) {
      const findChildren = treeFindPath(data.children, func, path);
      if (findChildren.length) return findChildren;
    }
    path.pop();
  }
  return [];
}

// 获取系统第一个菜单的数据
export function getPath(list) {
  for (let i = 0; i < list.length; i++) {
    const item = list[i]
    if (item.type != 'resource' && item.children?.length) {
      return getPath(item.children)
    } else {
      return item.code
    }
  }
}
