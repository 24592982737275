/* eslint-disable no-case-declarations */
import axios from "axios";
import { Message } from "element-ui";

const config = {
  baseURL: "/api",
  timeout: 60 * 1000,
  withCredentials: false,
};

const axiosInstance = axios.create(config);

axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.data.code === 302) {
        window.location.href = response.data.data
        return Promise.resolve(response.data)
      }
      if (
        response.data.code === 1 ||
        response.data.code === 200 ||
        response.data.code === 20000
      ) {
        return Promise.resolve(response.data);
      } else if (response.data.code === undefined) {
        return Promise.resolve(response);
      } else {
        Message({
          dangerouslyUseHTMLString: true,
          showClose: true,
          message: response.data.message,
          type: "error",
        });
        return Promise.reject(response.data);
      }
    } else {
      Message({
        dangerouslyUseHTMLString: true,
        showClose: true,
        message: response.data.message,
        type: "error",
      });
      return Promise.reject(response.data);
    }
  },
  (error) => {
    if (error?.response?.status) {
      switch (error.response.status) {
        case 401:
          break;
        case 404:
          Message.error("路径不存在");
          break;
        case 403:
          Message.error("您没有权限进行此操作，请联系您的管理员");
          break;
        case 504:
          Message.error("请求超时");
          break;
        default:
          Message.error(error.response?.data?.message);
      }
      return Promise.reject(error.response.data);
    }
  }
);

const http = {
  get(url, params) {
    return axiosInstance.get(url, { params: params });
  },
  post(url, params, config) {
    return axiosInstance.post(url, params, config);
  },
  delete(url, params) {
    return axiosInstance.delete(url, { params });
  },
  put(url, params) {
    return axiosInstance.put(url, params)
  },
};

export default http;
