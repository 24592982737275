import Vue from 'vue'
import Vuex from 'vuex'
import Api from '@/api'
import route from '@/router'
import { treeFindPath } from '@/utils'
Vue.use(Vuex)

const state = {
  isFoldSlider: false, // 是否折叠左侧栏
  isHideLeftMenu: false, // 是否隐藏左侧栏
  userInfo: { details: { enterpriseAccount: {}, generalAccount: {} } },
  appList: [], //所有系统菜单
  leftMenuData: [], // 左侧菜单数据
  routeMeta: {}, // 路由meta

  globalApp: [], //全局菜单global
  curAppData: {}, // 当前全局系统菜单数据
  localApp: [], //应用菜单app
  allLocalApp: {}, // 所有应用下的菜单集合
  curAppData2: {}, // 当前应用系统菜单数据
  appStatus: { isApp: false, isSelectApp: false }, // isApp:当前全局是否处于【应用】 isSelectApp:应用下的系统
  appCodeQuery: undefined, // query应用解决方案编码

  application: {}, // 当前应用
  applicationList: {}, // 应用列表

  breadcrumbData: [], //面包屑
  appschemeMenu: [], // 应用方案下的菜单
  authBtnList: [], // 按钮权限
  envList: [
    {
      label: '开发环境',
      value: 'dev',
      id: 1,
    },
    {
      label: '测试环境',
      value: 'test',
      id: 2,
    },
    {
      label: '压测环境',
      value: 'perf',
      id: 4,
    },
    {
      label: '验收环境',
      value: 'uat',
      id: 3,
    },
    {
      label: '生产环境',
      value: 'prod',
      id: 5,
    },
    {
      label: '生产环境A',
      value: 'prod_A',
      id: 6,
    },
  ],
}

const mutations = {
  SET_LEFT_MENU_DATA(state, data) {
    state.leftMenuData = data
    let curSystemCode = ''
    if (route.currentRoute.path.indexOf(state.curAppData?.code)) {
      // 判断当前系统是否是全局系统
      curSystemCode = state.curAppData.code
    } else {
      curSystemCode = state.curAppData2.code
    }
    sessionStorage.setItem('getLeftMenuData', JSON.stringify({ menudData: data, curSystemCode }))
  },
  SET_FOLD_SLIDER_STATUS(state, data) {
    state.isFoldSlider = data
  },
  SET_USER_INFO(state, data) {
    state.userInfo = data
  },
  SET_LEFT_MENU_SHOW_STATUS(state, data) {
    state.isHideLeftMenu = data
  },
  SET_ROUTE_META(state, data) {
    state.routeMeta = data
  },
  SET_APP(state, data) {
    state.appList = data
  },
  SET_CUR_APP(state, data) {
    state.curAppData = data || {}
  },
  SET_CUR_APP2(state, data) {
    state.curAppData2 = data || {}
  },
  SET_GLOBAL_APP(state, data) {
    state.globalApp = data
  },
  SET_LOCAL_APP(state, data) {
    state.localApp = data
  },
  SET_APP_STATUS(state, data) {
    state.appStatus = data
  },
  SET_APP_CODE_QUERY(state, data) {
    state.appCodeQuery = data
  },
  SET_APPLICATION(state, data) {
    state.application = data || {}
  },
  SET_APPLICATION_LIST(state, data) {
    state.applicationList = data
  },
  SET_BREADCRUMB_DATA(state, data) {
    state.breadcrumbData = data
  },
  SET_ALL_LOCAL_APP(state, data) {
    state.allLocalApp = data
  },
  SET_APPSCHEME_MENU(state, data) {
    state.appschemeMenu = data
  },
  SET_AUTH_BTN(state, data) {
    state.authBtnList = data
  },
}

const actions = {
  // 获取用户信息
  async GET_USER_INFO({ commit }) {
    const { data } = await Api.getUserInfo()
    commit('SET_USER_INFO', data)
  },
  // 获取菜单数据
  async GET_MENU_INFO({ commit, dispatch }) {
    try {
      const { records: apps } = (await Api.getApps({ size: 1000 })).data
      commit('SET_APPLICATION_LIST', apps)
    } catch (error) {
      console.error(error)
    }
    const { p_app_code } = route.currentRoute.query
    const { data } = await Api.getGlobalMenuData()
    let allLocalApp = {}
    let resMap = (await Api.getAppMenuData({})).data
    for (let key in resMap?.appAuthMap) {
      allLocalApp[key] = resMap.appAuthMap[key] ? resMap.authMap[resMap.appAuthMap[key]] : []
    }
    let appLocalApp = []
    console.log(p_app_code, route.currentRoute.query, '应用方案编码')
    let pathArr = route.currentRoute.path.split('/')

    if (p_app_code) {
      let localApp = allLocalApp[p_app_code] || []
      // 过滤掉方案下的菜单
      appLocalApp = _.filter(localApp, (item) => item.type != 'scheme_menus')
      const appschemeMenuData = _.filter(localApp, (item) => item.type == 'scheme_menus')

      commit('SET_APPSCHEME_MENU', appschemeMenuData)
      commit('SET_LOCAL_APP', appLocalApp)
      dispatch('GET_CUR_APP_INFO', appLocalApp)
      sessionStorage.setItem('appschemeMenuData', JSON.stringify(appschemeMenuData))
    } else {
      commit('SET_LOCAL_APP', [])
      dispatch('GET_CUR_APP_INFO', data)
    }
    if (pathArr.length > 1) {
      // 在设置系统修改权限后，刷新页面后，若当前用户没有设置系统权限的话，则直接跳转到首页
      if (pathArr[1] && pathArr[1] == 'micro-auth') {
        let isGlobal = _.find(data, (item) => item.code.includes('micro-auth'))
        if (!isGlobal) {
          route.push('/')
        }
      }
      // 判断当前路径是否有系统权限
      if (pathArr[1] && pathArr[1] !== 'helpDoc') {
        let tempCode = pathArr[1].replace('#', '')
        let isGlobalSys = _.find(data, (item) => item.code.includes(tempCode))
        let isAppSys = null
        if (p_app_code) {
          isAppSys = _.find(appLocalApp, (item) => item.code.includes(tempCode))
        }
        if (!isGlobalSys && !isAppSys) {
          route.push('/')
        }
      }
    }

    commit('SET_APP', data)
    commit('SET_GLOBAL_APP', data)
    commit('SET_ALL_LOCAL_APP', allLocalApp)
  },
  // 切换系统切换菜单信息
  GET_CUR_APP_INFO({ commit, state }, data) {
    setTimeout(() => {
      // console.log(route.currentRoute)
      const { path, query } = route.currentRoute
      const { p_id, c_id, p_app_code } = query
      // 面包屑
      let breadcrumbData = treeFindPath(data, (item) => item.code == path)
      breadcrumbData.pop()
      commit('SET_BREADCRUMB_DATA', breadcrumbData)
      // 应用解决方案编码
      commit('SET_APP_CODE_QUERY', !!p_app_code)
      // 全局系统
      if (p_id) {
        let pApp = _.find(state.globalApp, ['id', p_id])
        if (path.includes('/micro-app')) {
          commit('SET_APP_STATUS', { isApp: true, isSelectApp: false })
          commit('SET_CUR_APP', pApp)
        } else {
          commit('SET_APP_STATUS', { isApp: false, isSelectApp: false })
          commit('SET_CUR_APP', pApp)
        }
      }
      // 应用
      if (p_app_code) {
        let app = _.find(state.applicationList, ['appCode', p_app_code])
        commit('SET_APPLICATION', app)
      }
      // 应用系统(局部)
      if (c_id) {
        let cApp = _.find(data, ['id', c_id])
        if (cApp?.rangeFlag == 'app') {
          commit('SET_APP_STATUS', { isApp: true, isSelectApp: true })
          commit('SET_CUR_APP2', cApp)
        }
        return
      }

      // console.log("********************************");
      if (path === '/') {
        commit('SET_APP_STATUS', { isApp: true, isSelectApp: false })
        commit('SET_CUR_APP', data[0])
      } else {
        for (let item of data) {
          if ((item.rangeFlag == 'global') & path.includes('/micro-app')) {
            commit('SET_APP_STATUS', { isApp: true, isSelectApp: false })
            commit('SET_CUR_APP', item)
            break
          } else if ((item.rangeFlag == 'global') & path.includes(item.code)) {
            commit('SET_APP_STATUS', { isApp: false, isSelectApp: false })
            commit('SET_CUR_APP', item)
            break
          } else if ((item.rangeFlag == 'app') & path.includes(item.code)) {
            commit('SET_APP_STATUS', { isApp: true, isSelectApp: true })
            commit('SET_CUR_APP2', item)
            break
          }
        }
      }
    }, 100)
  },
  // 获取按钮权限
  async GET_AUTH_BTN({ commit }) {
    let btnList = []
    const { p_app_code } = route.currentRoute.query
    let pathArr = route.currentRoute.path.split('/')
    if (pathArr.length < 2) {
      return
    }
    if (p_app_code) {
      // 应用下的系统按钮权限
      btnList = (await Api.getAppBtnAuth({ code: '/' + pathArr[1] })).data || []
    } else {
      // 全局系统按钮权限
      if (pathArr[1] && pathArr[1] !== 'helpDoc') {
        btnList = (await Api.getGlobalBtnAuth({ code: '/' + pathArr[1] })).data || []
      }
    }
    localStorage.setItem('authBtnList', JSON.stringify(btnList))
    commit('SET_AUTH_BTN', btnList)
  },
  // 面包屑数据
  GET_BREADCRUMB_DATA({ commit }, data) {},
}

const getters = {
  leftMenuData: (state) => state.leftMenuData,
  isFoldSlider: (state) => state.isFoldSlider,
  userInfo: (state) => state.userInfo,
  isHideLeftMenu: (state) => state.isHideLeftMenu,
}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {},
})
