import { child_app_path, redirect_prefix } from "@/utils/constant";

export const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/home/index.vue"),
    meta: { title: "首页" },
  },
  {
    path: "/helpDoc",
    name: "Home",
    component: () => import("@/views/helpDoc.vue"),
    meta: { title: "帮助文档" },
  },
  {
    path: "*",
    component: () => import("@/layout/index"),
    children: [
      /****应用解决方案重定向 START****/
      {
        path: `/micro-dev${child_app_path}`,
        redirect: "/micro-dev/standerDev/appList",
      },
      {
        path: `/micro-devops${child_app_path}`,
        redirect: "/micro-devops/appDevops/appList",
      },
      {
        path: `/micro-ipaas-console${child_app_path}`,
        redirect: "/micro-ipaas-console/devops/applicationList",
      },
      {
        path: `/micro-workflow${child_app_path}`,
        redirect: "/micro-workflow/appList",
      },
      {
        path: `/bi${child_app_path}`,
        redirect: "/micro-app/standerDev/appMgt/myScheme",
      },
      /****应用解决方案重定向 END***/

      /****主系统首页重定向 START***/
      {
        path: "/micro-app",
        redirect: `${redirect_prefix}/micro-app/standerDev/appMgt/myApp`,
      },
      {
        path: "/micro-auth",
        redirect: `${redirect_prefix}/micro-auth/user2/index`,
      },
      {
        path: "/micro-operation",
        redirect: `${redirect_prefix}/micro-operation/appMgt/appList`,
      },
      {
        path: "/micro-ops",
        redirect: `${redirect_prefix}/micro-ops/opsMgt/fworkMgt/fworkDeploy`,
      },
      {
        path: "/cmp",
        redirect: `${redirect_prefix}/cmp/resource_list`,
      },
      {
        path: "/jump-micro-order",
        redirect: `${redirect_prefix}/micro-order/out-specifics`,
      },
      /****主系统首页重定向 END***/

      {
        path: "/micro-dev",
        redirect: "/micro-dev/standerDev/appList",
      },
      {
        path: "/micro-devops",
        redirect: "/micro-devops/appDevops/appMsg/index",
      },
      {
        path: "/micro-config",
        redirect: "/micro-config/userManage",
      },
      {
        path: "/micro-workflow",
        redirect: "/micro-workflow/flow/category",
      },
      {
        path: "/micro-ipaas-console",
        redirect: "/micro-ipaas-console/devops/apiList",
      },
      {
        path: "/micro-order",
        redirect: "/micro-order/my-todo",
      },
      {
        path: "/bi",
        redirect: "/bi/home",
      },

      {
        path: `${redirect_prefix}/:path(.*)`, // 主系统重定向
        component: () => import("@/views/redirect.vue"),
      },
      {
        path: "404", // 404
        component: () => import("@/views/errorPage/404.vue"),
        meta: { title: "Not Found" },
      },
    ],
  },
];
